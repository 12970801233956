






















































































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {Dapp} from '@/model/resource/Dapp'
import {Fragment} from 'vue-fragment'
import HomepageCardHeader from '@/components/cards/homepage/HomepageCardHeader.vue'
import HomepageCardContent from '@/components/cards/homepage/HomepageCardContent.vue'
import NetworkTag from '@/components/NetworkTag.vue'

type TopDapps = {
  title: string
  timeTitle: string
  seeAll: string
  dapps: Dapps[]
}

type Dapps = {
  idDapp: number
  title: string | null | undefined
  imageUrl: string | null | undefined
  transactionsTitle: string
  transactionsValue: number | null
  volumeTitle: string
  volumeValue: number | null
  blockchainVersionFormattedColor: string | undefined
  blockchainTagName: string | undefined
  slug: string | null
}

@Component({
  components: {
    NetworkTag,
    HomepageCardContent,
    HomepageCardHeader,
    PercentageProgress,
    Fragment,
  },
})
export default class TopDappsCard extends Vue {
  @Prop({type: Array, required: true})
  topDappsItems!: Dapp[]
  @Prop({type: Array, required: true})
  topDappsDailyCacheItems!: DappDailyCache[]

  items: TopDapps = {
    title: this.$translate('components.cards.TopDappsCard.title'),
    timeTitle: this.$translate('components.cards.TopDappsCard.timeTitle'),
    seeAll: this.$translate('components.cards.TopDappsCard.seeAll'),
    dapps: [],
  }

  async mounted() {
    await this.getData()
  }

  private async getData() {
    if (
      this.topDappsItems.length > 1 &&
      this.topDappsDailyCacheItems.length > 1
    ) {
      for (let i = 0; i <= this.topDappsItems.length - 1; i++) {
        const topDapp = this.topDappsItems[i]

        const transactionsValue = this.topDappsDailyCacheItems[i]
          ? this.topDappsDailyCacheItems[i].transactionCountCurrentWeek
          : 0

        const volumeValue = this.topDappsDailyCacheItems[i]
          ? this.topDappsDailyCacheItems[i].transactionCountPercentage
          : 0

        this.items.dapps.push({
          idDapp: topDapp.idDappPk,
          title: topDapp.title,
          imageUrl: topDapp.imageUrl,
          transactionsTitle: this.$translate(
            'components.cards.TopDappsCard.transactionsTitle'
          ),
          transactionsValue,
          volumeTitle: this.$translate(
            'components.cards.TopDappsCard.volumeTitle'
          ),
          volumeValue,
          blockchainVersionFormattedColor:
            topDapp.blockchainVersionFormattedColor,
          blockchainTagName: topDapp.blockchainTagName,
          slug: topDapp.slug,
        })
      }
    }
  }
}
