



















































































import {Component, Mixins} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import NetworkStatsView from '@/views/home/NetworkStatsView.vue'
import DappExploreList from '@/components/lists/DappExploreList.vue'
import GasbotHeader from '@/components/gasbot/GasbotHeader.vue'
import GasbotAbout from '@/components/gasbot/GasbotAbout.vue'
import LatestNewsView from '@/views/home/LatestNewsView.vue'
import BannerList from '@/components/lists/BannerList.vue'
import AppFooter from '@/components/AppFooter.vue'
import {MetaInfo} from 'vue-meta'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import GasCalculatorView from '@/views/home/GasCalculatorView.vue'
import TokenNftView from '@/views/home/TokenNftView.vue'

@Component({
  components: {
    GasCalculatorView,
    NavbarMenu,
    NetworkStatsView,
    DappExploreList,
    GasbotHeader,
    GasbotAbout,
    LatestNewsView,
    BannerList,
    AppFooter,
    TokenNftView,
  },
})
export default class HomeView extends Mixins(MixinScreenSize) {
  title!: String
  subtitle!: String

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.home'),
    }
  }

  titleFontSize() {
    return 'text-5xl xs:text-title'
  }
}
