

































































































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {DappCollection} from '@/model/collection/DappCollection'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import ExploreDappsCard from '@/components/cards/ExploreDappsCard.vue'
import {SwiperSlide} from 'vue-awesome-swiper'
import Swiper from 'swiper'

@Component({
  components: {
    ExploreDappsCard,
    SwiperSlide,
  },
})
export default class DappExploreList extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: false, default: 'text-title'})
  titleClass!: string

  collection = new DappCollection()

  isBeginning = true
  isEnd = true

  swiper!: Swiper

  get swiperOption() {
    return {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    }
  }

  async mounted() {
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.collection.trending = true
    this.collection.perPage = this.isMobile ? 5 : 10
    this.collection.hasContract = true
    this.collection.orderBy = 'score'
    this.collection.asc = false
    this.collection.private = false
  }

  async populateResources() {
    await this.$await.run('listDapp', () => this.collection.queryAsPage())
    await this.$nextTick()

    // TO-DO: Fix lib, block loading of more pages
    this.collection.setCurrentPage(this.collection.lastPage)
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
    this.updateEvent()
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
