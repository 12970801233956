





























































































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {Dapp} from '@/model/resource/Dapp'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {Fragment} from 'vue-fragment'
import HomepageCardHeader from '@/components/cards/homepage/HomepageCardHeader.vue'
import HomepageCardContent from '@/components/cards/homepage/HomepageCardContent.vue'
import NetworkTag from '@/components/NetworkTag.vue'

type RecentlyAdded = {
  title: string
  timeTitle: string
  seeAll: string
  dapps: Dapps[]
}

type Dapps = {
  idDapp: number
  title: string | null | undefined
  imageUrl: string | null | undefined
  transactionsTitle: string
  transactionsValue: number | null
  volumeTitle: string
  volumeValue: number | null
  blockchainVersionFormattedColor: string | undefined
  blockchainTagName: string | undefined
  isDapp: boolean | null
  slug: string | null
}

@Component({
  components: {
    NetworkTag,
    HomepageCardContent,
    HomepageCardHeader,
    PercentageProgress,
    Fragment,
  },
})
export default class RecentlyAddedCard extends Vue {
  @Prop({type: Array, required: true})
  recentlyAddedDappsItems!: Dapp[]

  @Prop({type: Array, required: true})
  recentlyAddedDappsDailyCacheItems!: DappDailyCache[]

  items: RecentlyAdded = {
    title: this.$translate('components.cards.RecentlyAddedCard.title'),
    timeTitle: this.$translate('components.cards.RecentlyAddedCard.timeTitle'),
    seeAll: this.$translate('components.cards.RecentlyAddedCard.seeAll'),
    dapps: [],
  }

  async mounted() {
    await this.getData()
  }

  private async getData() {
    if (this.recentlyAddedDappsItems.length > 1) {
      for (let i = 0; i <= this.recentlyAddedDappsItems.length - 1; i++) {
        const recentlyAddedDapp = this.recentlyAddedDappsItems[i]

        const transactionsValue = this.recentlyAddedDappsDailyCacheItems[i]
          ? this.recentlyAddedDappsDailyCacheItems[i]
              .transactionCountCurrentWeek
          : 0

        const volumeValue = this.recentlyAddedDappsDailyCacheItems[i]
          ? this.recentlyAddedDappsDailyCacheItems[i].transactionCountPercentage
          : 0

        this.items.dapps.push({
          idDapp: recentlyAddedDapp.idDappPk,
          title: recentlyAddedDapp.title,
          imageUrl: recentlyAddedDapp.imageUrl,
          transactionsTitle: this.$translate(
            'components.cards.RecentlyAddedCard.transactionsTitle'
          ),
          transactionsValue,
          volumeTitle: this.$translate(
            'components.cards.RecentlyAddedCard.volumeTitle'
          ),
          volumeValue,
          blockchainVersionFormattedColor:
            recentlyAddedDapp.blockchainVersionFormattedColor,
          blockchainTagName: recentlyAddedDapp.blockchainTagName,
          isDapp: recentlyAddedDapp.isDapp,
          slug: recentlyAddedDapp.slug,
        })
      }
    }
  }
}
