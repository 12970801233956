var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"explore-dapps-card",attrs:{"to":{
    name: 'dappDetailedView',
    params: {id: _vm.dapp.slug},
  }}},[_c('div',{staticClass:"explore-dapps-card__image-container"},[_c('img',{staticClass:"w-full h-full rounded-lg md:rounded-xl shadow-drop-xs",attrs:{"src":_vm.dapp.imageUrl,"alt":"Dapp image"}}),(!_vm.isLessThanTablet)?_c('div',{staticClass:"absolute left-2 top-2 m-1"},[(_vm.dapp.blockchainVersionTitle)?_c('span',{staticClass:"px-2 py-1 rounded-full text-black text-sm font-bold uppercase",class:{
          'text-2xs': _vm.isMobile,
          'text-sm': _vm.isDesktop,
        },style:(_vm.dapp.blockchainVersionFormattedColor)},[_vm._v(" "+_vm._s(_vm.dapp.blockchainTagName)+" ")]):_vm._e(),(!_vm.dapp.inActiveDevelopment)?_c('span',{staticClass:"px-2 py-1 rounded-full text-black text-sm lowercase bg-cadet-blue bg-cadet-blue"},[_vm._v(" "+_vm._s(_vm.$t('app.inactive'))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"verti gap-y-3 w-full"},[_c('div',{staticClass:"horiz items-center gap-x-2"},[_c('span',{staticClass:"explore-dapps-card__item-name",class:{
          'font-bold': _vm.dapp.dappDailyCache,
          'font-semibold': !_vm.dapp.dappDailyCache,
        }},[_vm._v(" "+_vm._s(_vm.dapp.title)+" ")]),(_vm.isMobile)?_c('div',{staticClass:"horiz items-center"},[_c('span',{staticClass:"rounded-full h-3 w-3 mr-2",style:(_vm.dapp.blockchainVersionFormattedColor)}),_c('span',{staticClass:"uppercase truncate font-semibold text-gray-600 text-sm"},[_vm._v(" "+_vm._s(_vm.dapp.blockchainTagName)+" ")])]):_vm._e()]),_c('div',{staticClass:"verti gap-y-1"},[_c('div',{staticClass:"horiz"},[_c('span',{staticClass:"weight-1 text-sm uppercase font-medium text-black"},[_vm._v(" "+_vm._s(_vm.$t('components.dappCard.7DayTransactions'))+" ")]),(_vm.showPercentage)?_c('span',{staticClass:"text-sm text-right font-medium text-black"},[_vm._v(" "+_vm._s(_vm.$t('components.dappCard.vol'))+" ")]):_vm._e()]),_c('div',{staticClass:"horiz"},[_c('div',{staticClass:"mr-1 weight-1"},[_c('span',{staticClass:"text-3xl text-bright-gray font-semibold fix-truncate"},[_vm._v(" "+_vm._s(_vm.$filter.decimal(_vm.dapp.dappDailyCache.transactionCountCurrentWeek))+" ")])]),(_vm.showPercentage)?_c('div',{staticClass:"horiz items-center"},[_c('percentage-progress',{attrs:{"percentage-value":_vm.dapp.dappDailyCache
                ? _vm.dapp.dappDailyCache.transactionCountPercentage
                : 0}})],1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }